export async function setup() {
  const videoData = [];

  videoData.XL = [];
  videoData.XL.English = [];
  videoData.XL.English.stage2 = require("../assets/video/XL/English/stage2.mp4");
  videoData.XL.English.stage2Subs = require("../assets/video/XL/English/stage2.vtt");
  videoData.XL.English.stage3 = require("../assets/video/XL/English/stage3.mp4");
  videoData.XL.English.stage3Subs = require("../assets/video/XL/English/stage3.vtt");
  videoData.XL.English.stage5 = require("../assets/video/XL/English/stage5.mp4");
  videoData.XL.English.stage5Subs = require("../assets/video/XL/English/stage5.vtt");
  videoData.XL.English.stage7 = require("../assets/video/XL/English/stage7.mp4");
  videoData.XL.English.stage7Subs = require("../assets/video/XL/English/stage7.vtt");
  videoData.XL.English.stage9 = require("../assets/video/XL/English/stage9.mp4");
  videoData.XL.English.stage9Subs = require("../assets/video/XL/English/stage9.vtt");
  videoData.XL.English.stage11 = require("../assets/video/XL/English/stage11.mp4");
  videoData.XL.English.stage11Subs = require("../assets/video/XL/English/stage11.vtt");
  videoData.XL.English.stage12 = require("../assets/video/XL/English/stage12.mp4");
  videoData.XL.English.stage12Subs = require("../assets/video/XL/English/stage12.vtt");
  
  videoData.ncs = [];
  videoData.ncs.English = [];
  videoData.ncs.English.stage2 = require("../assets/video/ncs/English/stage2.mp4");
  videoData.ncs.English.stage2Subs = require("../assets/video/ncs/English/stage2.vtt");
  videoData.ncs.English.stage3 = require("../assets/video/ncs/English/stage3.mp4");
  videoData.ncs.English.stage3Subs = require("../assets/video/ncs/English/stage3.vtt");
  videoData.ncs.English.stage5 = require("../assets/video/ncs/English/stage5.mp4");
  videoData.ncs.English.stage5Subs = require("../assets/video/ncs/English/stage5.vtt");
  videoData.ncs.English.stage7 = require("../assets/video/ncs/English/stage7.mp4");
  videoData.ncs.English.stage7Subs = require("../assets/video/ncs/English/stage7.vtt");
  videoData.ncs.English.stage9 = require("../assets/video/ncs/English/stage9.mp4");
  videoData.ncs.English.stage9Subs = require("../assets/video/ncs/English/stage9.vtt");
  videoData.ncs.English.stage11 = require("../assets/video/ncs/English/stage11.mp4");
  videoData.ncs.English.stage11Subs = require("../assets/video/ncs/English/stage11.vtt");
  videoData.ncs.English.stage12 = require("../assets/video/ncs/English/stage12.mp4");
  videoData.ncs.English.stage12Subs = require("../assets/video/ncs/English/stage12.vtt");
  return videoData;


}
