export async function setup() {
  const logoData = {};
  logoData["XL"] = require("../assets/logo.png");
  logoData["bth"] = require("../assets/bth.png");
  logoData["arkh"] = require("../assets/arkh.png");
  logoData["directline"] = require("../assets/directline.png");
  logoData["kentuni"] = require("../assets/kentuni.png");
  logoData["gatwick"] = require("../assets/gatwick.png");
  logoData["genting"] = require("../assets/genting.png");
  logoData["gbg"] = require("../assets/gbg2.png");
  logoData["fclg"] = require("../assets/fclg.png");
  logoData["ncs"] = require("../assets/ncs.png");
  return logoData;
}
